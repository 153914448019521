import { Observable, Subject, of, switchMap } from 'rxjs';

import { CompanyInfo } from '@app/core/models/company-info.model';
import { CompanyProfile } from '@app/core/models/company-profile.model';
import { CompanySnapshot } from '@app/core/models/company-snapshot.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseModel } from '@app/core/models/response.model';
import { environment } from '@env/environment';

@Injectable()
export class CompanyService {
	private URL: string = environment.apiUrl + 'companies/';
	private reloadCompaniesSubject = new Subject<string>();
	reloadCompanies$ = this.reloadCompaniesSubject.asObservable();

	constructor(private http: HttpClient) {}

	reloadCompanies(name: string) {
		this.reloadCompaniesSubject.next(name);
	}

	getCompanyInfoByUserId(userId: number): Observable<CompanyInfo> {
		return this.http.get(`${this.URL}company-info?userId=${userId}`).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<CompanyInfo>;
				return of(res.data);
			}),
		);
	}

	getCompanyInfoById(id: number): Observable<CompanyInfo> {
		return this.http.get(`${this.URL}company-info-id?id=${id}`).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<CompanyInfo>;
				return of(res.data);
			}),
		);
	}

	searchCompany(name: string): Observable<CompanyInfo[]> {
		return this.http.get(`${this.URL}search-company?Name=${name}`).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<CompanyInfo[]>;
				return of(res.data);
			}),
		);
	}

	getCompanyProfile(uuid: string = ''): Observable<CompanyProfile> {
		return this.http.get(`${this.URL}company-profile?uuid=${uuid}`).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<CompanyProfile>;
				return of(res.data);
			}),
		);
	}

	getCompanySnapshot(uuid: string): Observable<CompanySnapshot> {
		const { URL } = this;
		return this.http.get(`${URL}company-snapshot?uuid=${uuid}`).pipe(
			switchMap((response) => {
				const res = response as ResponseModel<CompanySnapshot>;
				return of(res.data);
			}),
		);
	}

	emailIncident(request: {
		uuid: string;
		message: string;
	}): Observable<boolean> {
		const { URL } = this;
		return this.http.post(`${URL}email-incident`, request).pipe(
			switchMap((response) => {
				const res = response as ResponseModel<boolean>;
				return of(res.data);
			}),
		);
	}
}

